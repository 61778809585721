import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faChartColumn, faHeart, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';

import styles from './Contest.module.scss';
import Button from '../../components/Button';
import Time from '../../components/Time';
import CardContest from './components/CardContest';
import request from '../../utils/request';
import { shuffleExample, getExampleRetry, copyTextToClipboard } from '../../helpers';
import PopupFinally from './components/PopupFinally';
import PopupTimeout from './components/PopupTimeout';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
const cx = classNames.bind(styles);
const initExample = [
    {
        name: 'Phần 1',
        questions: [
            {
                name: 'Có thể đề thi này bị lỗi !',
                answers: [],
            },
        ],
    },
];

function Contest() {
    const { state } = useLocation();
    const [isLoading, setIsloading] = useState(true);
    const { delayNext, isQuestionShuffle, isAnswerShuffle, test } = state
        ? state
        : { delayNext: 1, isQuestionShuffle: false, isAnswerShuffle: false };

    const [listExample, setListExample] = useState(initExample);
    const [result, setResult] = useState({ correct: 0, wrong: 0 });
    const [timeSkipQuestion, setTimeSkipQuestion] = useState(state ? +delayNext : 1);
    const [curentPart, setCurentPart] = useState(0);
    const [curentQuestion, setCurentQuestion] = useState(0);
    const [example, setExample] = useState([]);
    const [searchParams] = useSearchParams();
    const { subject } = useParams();
    const popupTimeOutRef = useRef();
    const timeRef = useRef();
    const [endExample, setEndExample] = useState(false);

    const [isLike, setIsLike] = useState(false);
    const [isHeart, setIsHeart] = useState(false);

    const retryExampleFalse = () => {
        const newListExample = getExampleRetry(listExample);
        setListExample(newListExample);
        setCurentPart(0);
        setCurentQuestion(0);
        setResult({ correct: 0, wrong: 0 });
    };

    const handleGetExample = () => {
        const id = searchParams.get('id');
        if (id) {
            request
                .get('/getExampleQ', { params: { tagExample: id } })
                .then((data) => {
                    setListExample(data.data);
                })
                .catch(() => {
                    setListExample([...initExample]);
                });
        } else {
            request
                .get('/getQuestion', { params: { id: subject } })
                .then((data) => {
                    const exam = JSON.parse(data.data.question);
                    setIsloading(false);

                    setExample(data.data);
                    setListExample(shuffleExample([...exam], isQuestionShuffle, isAnswerShuffle));
                })
                .catch((err) => {
                    console.log(err);

                    setListExample([...initExample]);
                });
        }
    };
    useEffect(() => {
        timeRef.current && timeRef.current.start(test ? example.credits * 15 * 60 : 0);
        setIsLike(!!example.like);
        setIsHeart(!!example.favorited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleUpdateAction = (data) => {
        request.post('update-action', { like: isLike, favorited: isHeart, ...data, example_id: example.id });
    };

    useEffect(() => {
        handleGetExample();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, subject]);

    const handleTimeOut = () => {
        if (!(result.correct + result.wrong === example.num_question)) {
            popupTimeOutRef.current.onOpen();
        }
    };
    const handleRetry = () => {
        handleGetExample();
        setEndExample(false);
        setResult({ correct: 0, wrong: 0 });
        timeRef.current.restart();
        setCurentPart(0);
        setCurentQuestion(0);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Custom message or action before the page unloads
            event.preventDefault();
            event.returnValue = ''; // Một số trình duyệt yêu cầu trả về giá trị rỗng
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup khi component unmount hoặc khi useEffect chạy lại
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className={cx('wraper')}>
            {/* Thông tin dề */}
            <div>
                <div className={cx('info-example', 'box')}>
                    <h1>{example.name}</h1>
                    <div className={cx('info-author')}>
                        <img src={example.avatar} alt="Ảnh tác giả"></img>
                        <p className={cx('name')}>{example.username}</p>
                    </div>
                    <div className={cx('chart')}>
                        <div>
                            <FontAwesomeIcon icon={faChartColumn}></FontAwesomeIcon>
                            <p>{example.num_question}</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faChartColumn}></FontAwesomeIcon>
                            <p>{example.count_test}</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faChartColumn}></FontAwesomeIcon>
                            <p>{example.count_like}</p>
                        </div>
                    </div>
                    <hr></hr>
                    <div className={cx('total')}>
                        <p>Thời gian làm bài thi:</p>
                        <Time
                            time={100}
                            ref={timeRef}
                            countDown={test}
                            notAlwayRunning
                            finishFn={handleTimeOut}
                            className={cx('time')}
                        ></Time>
                    </div>
                    <div className={cx('other')}>
                        <div className={cx('time')}>
                            <label>Tự động chuyển câu:</label>
                            <select
                                value={timeSkipQuestion}
                                onChange={(e) => setTimeSkipQuestion(parseInt(e.target.value))}
                            >
                                <option value={0}>Tắt</option>
                                <option value={1}>1s</option>
                                <option value={2}>2s</option>
                                <option value={3}>3s</option>
                            </select>
                        </div>
                        <hr></hr>
                        <div className={cx('other-group')}>
                            <Button
                                icon={faCopy}
                                onClick={() => {
                                    copyTextToClipboard(`${process.env.REACT_APP_DOMAIN}/share/${example.id}`);
                                }}
                                iconColor="var(--text-color)"
                            ></Button>
                            <div>
                                <Button
                                    icon={faThumbsUp}
                                    onClick={() => {
                                        setIsLike((prev) => !prev);
                                        handleUpdateAction({ like: !isLike });
                                    }}
                                    className={cx('button')}
                                    {...(isLike && { iconColor: '#067eff' })}
                                ></Button>
                                <Button
                                    icon={faHeart}
                                    onClick={() => {
                                        setIsHeart((prev) => !prev);
                                        handleUpdateAction({ favorited: !isHeart });
                                    }}
                                    {...(isHeart && { iconColor: '#f93a52' })}
                                    className={cx('button')}
                                ></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('part-example', 'box')}>
                    <h1 className={cx('header')}>Danh sách phần thi</h1>
                    <div className={cx('content')}>
                        {listExample.map((part, index) => {
                            return (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        setCurentPart(index);
                                        setCurentQuestion(0);
                                    }}
                                    className={cx('button', { active: index === curentPart })}
                                    leftIcon={faBookOpen}
                                >
                                    <h1>{part.name}</h1>
                                    <p>{part.questions.length} câu hỏi</p>
                                </Button>
                            );
                        })}
                    </div>
                    <div className={cx('result')}>
                        <div>
                            <p>Đúng : </p>
                            <p className={cx('correct')}>{result.correct}</p>
                        </div>
                        <div>
                            <p>Sai : </p>
                            <p className={cx('wrong')}>{result.wrong}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Khu vực thi */}
            <div>
                <CardContest
                    className={cx('box')}
                    listExample={listExample}
                    curentPart={curentPart}
                    curentQuestion={curentQuestion}
                    setCurentQuestion={setCurentQuestion}
                    setListExample={setListExample}
                    timeSkipQuestion={timeSkipQuestion}
                    setCurentPart={setCurentPart}
                    setResult={setResult}
                ></CardContest>
            </div>
            {/* Khu vực chuyển câu hỏi */}
            <div className={cx('example-table', 'box')}>
                <div className={cx('header')}>
                    <h3>Mục lục</h3>
                </div>
                <div className={cx('content')}>
                    {listExample[curentPart].questions.map((item, index) => {
                        let classes = cx('button', {
                            wrong: !item.correct && item.correct !== undefined,
                            correct: item.correct && item.correct !== undefined,
                            active: index === curentQuestion,
                        });
                        return (
                            <Button key={index} onClick={() => setCurentQuestion(index)} className={classes}>
                                {index + 1}
                            </Button>
                        );
                    })}
                    {/* <Button className={cx('button')}>1</Button> */}
                </div>
                <div className={cx('retry')}>
                    <Button onClick={retryExampleFalse} primary>
                        Làm lại câu sai
                    </Button>
                </div>
            </div>
            <PopupTimeout
                ref={popupTimeOutRef}
                onClose={() => {
                    setEndExample(true);
                }}
            ></PopupTimeout>
            {(result.correct + result.wrong === example.num_question || endExample) && (
                <PopupFinally
                    listExample={listExample}
                    exampleResult={{
                        name: example.name,
                        num_question: example.num_question,
                        correct: result.correct,
                        score: (10 / example.num_question) * result.correct,
                        time: timeRef.current.getTime(),
                    }}
                    onOpen={timeRef.current.stop()}
                    handleRetry={handleRetry}
                    handleRetryExample={retryExampleFalse}
                ></PopupFinally>
            )}
        </div>
    );
}

export default Contest;
