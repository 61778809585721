import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import styles from './ExamQuestions.module.scss';
import CardExample from '../../components/CardExample';
import request from '../../utils/request';
import Button from '../../components/Button';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
const cx = classNames.bind(styles);

function ExamQuestions() {
    const [listExample, setListExample] = useState([]);
    const [page, setPage] = useState({});
    const { state } = useLocation();

    const search = state ? state.search : '';
    const handleNextPage = () => {
        axios
            .get(page.nextPageUrl)
            .then((example) => {
                handleChangePage(example.data);
            })
            .catch(() => {});
    };
    const handlePreviousPage = () => {
        axios
            .get(page.prevPageUrl)
            .then((example) => {
                handleChangePage(example.data);
            })
            .catch(() => {});
    };
    const handleChangePage = (example) => {
        const { prev_page_url, next_page_url } = example;
        window.scrollTo(0, 0);
        setPage({ prevPageUrl: prev_page_url, nextPageUrl: next_page_url });
        setListExample(example.data);
    };

    useEffect(() => {
        request
            .get('get-example', { params: { search } })
            .then((example) => {
                handleChangePage(example.data);
            })
            .catch(() => {});
    }, [search]);

    return (
        <div className={cx('wraper')}>
            {listExample.length > 0 ? (
                listExample.map((example, index) => {
                    return <CardExample className={cx('child')} key={index} example={example}></CardExample>;
                })
            ) : (
                <>
                    <CardExample></CardExample>
                    <CardExample></CardExample>
                    <CardExample></CardExample>
                </>
            )}
            <div className={cx('page')}>
                <Button primary disable={!page.prevPageUrl} onClick={handlePreviousPage}>
                    Trang trước
                </Button>
                <Button primary disable={!page.nextPageUrl} onClick={handleNextPage}>
                    Trang tiếp
                </Button>
            </div>
        </div>
    );
}
export default ExamQuestions;
