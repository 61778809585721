import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './CreateExample.module.scss';
import Button from '../../components/Button';
import request from '../../utils/request';
import Input from '../../components/Input';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { convertExampleToText, convertTextToExample } from '../../helpers';
import { routes } from '../../config';
import { useAuth } from '../../hooks/useAuth';

const cx = classNames.bind(styles);
const initExample = [
    {
        name: 'Phần 1',
        questions: [
            {
                name: 'Vui lòng nhập câu hỏi',
                answers: [],
            },
        ],
    },
];
function CreateExample() {
    // Take example
    const { user } = useAuth();
    const keyRef = useRef();
    // end
    const [listExample, setListExample] = useState([
        {
            name: 'Phần 1',
            questions: [
                {
                    name: 'Câu 1',
                    answers: [
                        { option: 'A. slow', is_correct: false },
                        { option: 'B. slow', is_correct: false },
                        { option: 'C. slow', is_correct: false },
                        { option: 'D. slow', is_correct: true },
                    ],
                },
            ],
        },
    ]);
    const { toastPromise } = useGlobalContext();
    const [curentPart, setCurentPart] = useState(0);
    const [curentQuestion, setCurentQuestion] = useState(0);
    const [textarea, setTextarea] = useState('');
    const navigate = useNavigate();
    const nameExampleRef = useRef();
    const sectorRef = useRef();
    const creditsRef = useRef();
    const [creating, setCreating] = useState(false);

    const handleChangePart = (index) => {
        setCurentPart(index);
        setCurentQuestion(0);
    };
    const handleChangeTextarea = (e) => {
        setTextarea(e.target.value);
    };
    const handleCreateExample = async () => {
        const count_question = listExample.reduce((total, item) => {
            total += item.questions.length;
            return total;
        }, 0);
        if (textarea.trim()) {
            const toastCreatExample = toastPromise('Đang tạo đề thi...');
            setCreating(true);
            await request
                .post('/create-example', {
                    name: nameExampleRef.current.getValue(),
                    credits: creditsRef.current.getValue(),
                    count_question,
                    sector: sectorRef.current.getValue(),
                    question: JSON.stringify(listExample),
                })
                .then((data) => {
                    toastCreatExample.success('Tạo đề thi thành công !');
                    navigate(routes.myExam);
                })
                .catch((err) => {
                    toastCreatExample.error('Tạo đề thi thất bại !');
                })
                .finally(() => {
                    setCreating(false);
                });
        }
    };
    useEffect(() => {
        const objExample = convertTextToExample(textarea);

        if (textarea && textarea.trim()) {
            if (!objExample[curentPart]) {
                setCurentPart(objExample.length - 1);
            } else {
                const curentPartExample = objExample[curentPart].questions;
                if (!curentPartExample[curentQuestion]) {
                    setCurentQuestion(curentPartExample.length - 1);
                }
            }
            setListExample([...objExample]);
        } else {
            setListExample([...initExample]);
            setCurentPart(0);
        }
    }, [curentPart, curentQuestion, textarea]);
    return (
        <div className={cx('wraper')}>
            <div className={cx('edit', 'box')}>
                <h1>Thêm mới đề thi bằng văn bản</h1>
                <div className={cx('example-info')}>
                    <div className={cx('input-group')}>
                        <div style={{ display: 'flex', margin: '16px 0' }}>
                            <Input
                                ref={nameExampleRef}
                                validates={{ require: 'Bắt buộc nhập tên đề thi !' }}
                                title="Tên đề thi"
                                type="text"
                            ></Input>
                            <Input
                                style={{ margin: '0 16px' }}
                                validates={{ require: 'Bắt buộc nhập số tín chỉ !' }}
                                ref={creditsRef}
                                title="Số tín chỉ"
                                type="number"
                            ></Input>
                            {/* Take example */}
                            {user.role === 'admin' && (
                                <div>
                                    <Input ref={keyRef}></Input>
                                    <Button
                                        primary
                                        onClick={() => {
                                            const toastGetExample = toastPromise('Đang lấy dữ liệu');
                                            request
                                                .get('/getExampleQ', {
                                                    params: { tagExample: keyRef.current.getValue() },
                                                })
                                                .then((data) => {
                                                    setTextarea(convertExampleToText(data.data));
                                                    toastGetExample.success('Lấy dữ liệu thành công !');
                                                })
                                                .catch(() => {
                                                    toastGetExample.error('Không có đề thi nào vơi key trên');
                                                });
                                        }}
                                    >
                                        Clone
                                    </Button>
                                </div>
                            )}
                        </div>
                        <Input
                            ref={sectorRef}
                            title="Ngành"
                            type="select"
                            data={[
                                { title: 'Công nghệ thông tin' },
                                { title: 'Ngôn ngữ trung' },
                                { title: 'Công nghệ kỹ thuật điện tử' },
                                { title: 'Răng hàm mặt' },
                            ]}
                        ></Input>
                    </div>
                </div>
                <h1>Nơi để nhập đề thi</h1>
                <div className={cx('core')}>
                    <textarea spellCheck={false} value={textarea} onChange={(e) => handleChangeTextarea(e)}></textarea>
                </div>
                <p>Vui lòng soạn câu hỏi theo đúng cấu trúc</p>
                <div className={cx('button')}>
                    <Button
                        disable={creating}
                        onClick={handleCreateExample}
                        primary
                        validateInput={[nameExampleRef, creditsRef]}
                    >
                        Xác nhận
                    </Button>
                </div>
            </div>
            <div className={cx('preview', 'box')}>
                <div className={cx('header')}>
                    <h1>Xem trước đề thi</h1>
                </div>
                <div className={cx('content')}>
                    <div className={cx('part')}>
                        {listExample.map((part, index) => {
                            const typeButton = curentPart === index ? { primary: true } : { outline: true };
                            return (
                                <Button
                                    key={index}
                                    onClick={() => handleChangePart(index)}
                                    {...typeButton}
                                    small
                                    className={cx('button')}
                                >
                                    {part.name}
                                </Button>
                            );
                        })}
                        {/* <Button primary small className={cx('button')}>
                            Phần 1
                        </Button> */}
                    </div>
                    <div className={cx('example-group')}>
                        <div className={cx('group-header')}>
                            <h1>Danh sách câu hỏi</h1>
                            <div className={cx('list')}>
                                {listExample[curentPart].questions.map((question, index) => {
                                    const typeButton = curentQuestion === index ? { primary: true } : { outline: true };
                                    return (
                                        <Button
                                            key={index}
                                            onClick={() => setCurentQuestion(index)}
                                            {...typeButton}
                                            small
                                            className={cx('button')}
                                        >
                                            {index + 1}
                                        </Button>
                                    );
                                })}
                                {/* <Button primary small className={cx('button')}>
                                    1
                                </Button> */}
                            </div>
                        </div>
                        <div className={cx('example')}>
                            <div className={cx('header')}>
                                <h1>Câu {curentQuestion + 1}</h1>
                            </div>
                            <div className={cx('card-content')}>
                                <div className={cx('question')}>
                                    {listExample[curentPart].questions[curentQuestion].name}
                                </div>
                                <div className={cx('answer-group')}>
                                    {listExample[curentPart].questions[curentQuestion].answers.map((answer, index) => {
                                        const classes = cx('answer', {});
                                        const Icon = answer.is_correct ? (
                                            <FontAwesomeIcon color="green" icon={faCircleCheck}></FontAwesomeIcon>
                                        ) : (
                                            <FontAwesomeIcon color="red" icon={faCircleXmark}></FontAwesomeIcon>
                                        );
                                        return (
                                            <div key={index} className={classes}>
                                                {Icon}
                                                <p>{answer.option}</p>
                                            </div>
                                        );
                                    })}
                                    {/* <div className={cx('answer')}>
                                        <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon>
                                        <p>Đáp án A</p>
                                    </div>
                                     */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateExample;
