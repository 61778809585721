import classNames from 'classnames/bind';
import { routes } from '../../config';
import style from './NotFoundPage.module.css';
import { Link } from 'react-router-dom';
const cx = classNames.bind(style);
function NotFoundPage() {
    return (
        <div className={cx('wrapper')}>
            <div class={cx('noise')}></div>
            <div class={cx('overlay')}></div>
            <div class={cx('terminal')}>
                <h1>
                    Error <span class={cx('errorcode')}>404</span>
                </h1>
                <p class={cx('output')}>
                    The page you are looking for might have been removed, had its name changed or is temporarily
                    unavailable.
                </p>
                <p class={cx('output')}>
                    Please try to <Link to="#1">go back</Link> or <Link to={routes.home}>return to the homepage</Link>.
                </p>
                <p class={cx('output')}>Good luck.</p>
            </div>
        </div>
    );
}
export default NotFoundPage;
