import { useRef } from 'react';
import Button from '../../components/Button';
import DropImage from '../../components/DropImage';
import 'tippy.js/dist/tippy.css';

function TestComponent() {
    const imgref = useRef();
    return (
        <>
            <DropImage ref={imgref} />
            <Button
                primary
                onClick={() => {
                    imgref.current
                        .uploadImage()
                        .then((data) => {
                            console.log(data.url);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }}
            >
                Tải ảnh lên
            </Button>
        </>
    );
}

export default TestComponent;
