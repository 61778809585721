import { toast } from 'react-toastify';

const copyTextToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            toast('Sao chép thành công', { autoClose: 2000 });
        })
        .catch((err) => {
            console.error('Copy thất bại: ', err);
        });
};
export default copyTextToClipboard;
