import { useEffect, useState } from 'react';
import CardExample from '../../components/CardExample';
import request from '../../utils/request';
import { useParams } from 'react-router-dom';

function ShareExample() {
    const { id } = useParams();
    const [example, setExample] = useState(null);
    useEffect(() => {
        request
            .get('get-example', {
                params: {
                    id: id,
                },
            })
            .then((data) => {
                setExample(data.data.data[0]);
            })
            .catch();
    }, [id]);
    console.log(example);

    return (
        <div>
            <CardExample example={example}></CardExample>
        </div>
    );
}

export default ShareExample;
