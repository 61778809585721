import classNames from 'classnames/bind';

import Button from '../Button';
import style from './DropImage.module.scss';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import request from '../../utils/request';
const cx = classNames.bind(style);
function DropImage({ url, className, style = {}, ...props }, ref) {
    const [file, setFile] = useState();
    const [image, setImage] = useState(url);
    const [text, setText] = useState('Kéo và thả để tải ảnh lên');
    const inputRef = useRef();
    const handleImageUpload = (file) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
            setImage(fileReader.result);
            setFile(file);
        };
    };
    const getIdFromUrl = (url) => {
        const parts = url.split('/');
        const id = parts[parts.length - 1].split('.')[0];
        return id;
    };
    useImperativeHandle(ref, () => ({
        async uploadImage() {
            var formData = new FormData();
            if (image !== url) {
                if (url) {
                    formData.append('id', getIdFromUrl(url));
                }
                if (image) {
                    formData.append('image', file);
                }
                const data = await request.post('/upload_image', formData);
                return data.data;
            }
            const promise = new Promise((reslove, reject) => {
                reslove({ url });
            });
            return await promise;
        },
    }));
    return (
        <div
            className={cx('wrapper', className)}
            onDragOver={(e) => {
                e.preventDefault();
                setText('Thả để tải ảnh');
            }}
            onDragLeave={() => {
                setText('Kéo và thả để tải ảnh lên');
            }}
            onDrop={(e) => {
                e.preventDefault();

                const file = e.dataTransfer.files[0];
                if (file) {
                    handleImageUpload(file);
                } else {
                    alert('Định dạng file không đúng');
                }
            }}
        >
            {image ? (
                <>
                    <button
                        className={cx('btn-close')}
                        onClick={() => {
                            setImage(null);
                            setText('Kéo và thả để tải ảnh lên');
                        }}
                    >
                        X
                    </button>
                    <img className={cx('img')} src={image} alt="Ảnh được tải lên"></img>
                </>
            ) : (
                <>
                    <div>{text}</div>
                    <span>hoặc</span>
                    <Button
                        onClick={() => {
                            inputRef.current.click();
                        }}
                        primary
                    >
                        Chọn File
                    </Button>
                    <input
                        ref={inputRef}
                        accept="image/*"
                        type="file"
                        hidden
                        onChange={(e) => {
                            const file = e.target.files[0];
                            handleImageUpload(file);
                        }}
                    ></input>
                </>
            )}
        </div>
    );
}

export default forwardRef(DropImage);
