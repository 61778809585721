import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';

import styles from './MyExample.module.scss';
import CardExample from '../../components/CardExample';
import request from '../../utils/request';
import Input from '../../components/Input';
const cx = classNames.bind(styles);

function MyExample() {
    const [listMyExample, setListMyExample] = useState([]);
    const getExample = useCallback((query = '') => {
        request
            .get('get-myexample', { params: { query } })
            .then((data) => {
                setListMyExample(data.data);
            })
            .catch(() => {});
    }, []);
    useEffect(() => {
        getExample();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={cx('wraper')}>
            <div className={cx('filter')}>
                <Input
                    title="Tìm kiếm"
                    handleDebounce={(value) => {
                        getExample(value);
                    }}
                ></Input>
                <div className={cx('tag')}></div>
            </div>
            <div className={cx('content')}>
                {listMyExample.map((example, index) => {
                    return <CardExample getExample={getExample} key={index} example={example} myExample></CardExample>;
                })}
            </div>
        </div>
    );
}

export default MyExample;
