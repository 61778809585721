import axios from 'axios';

const request = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: 'Accept: application/json',
});
request.interceptors.response.use(
    (response) => {
        const convertStringToNumber = (data) => {
            if (typeof data === 'object' && data !== null) {
                Object.keys(data).forEach((key) => {
                    if (typeof data[key] === 'string' && !isNaN(data[key])) {
                        data[key] = Number(data[key]);
                    } else if (typeof data[key] === 'object') {
                        convertStringToNumber(data[key]);
                    }
                });
            }
            return data;
        };

        response.data = convertStringToNumber(response.data);

        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

request.defaults.withCredentials = true;
request.defaults.withXSRFToken = true;
export default request;
