const getExampleRetry = (listExample) => {
    const newExample = JSON.parse(JSON.stringify(listExample));
    newExample.forEach((part) => {
        part.questions = part.questions.filter((question) => !question.correct);
        part.questions.forEach((question) => {
            if (!question.correct) {
                delete question.choose;
                delete question.correct;
            }
        });
    });

    return newExample;
};
export default getExampleRetry;
